.container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.container::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0;
  -webkit-appearance: none;
  appearance: none;
}

.preloader {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    box-shadow: 0px -30px #00b5d8, 10px -30px #00b5d8, 20px -20px #00b5d8, 30px -10px #00b5d8,
      30px 0px #00b5d8, 30px 10px #00b5d8, 20px 20px #00b5d8, 10px 30px #00b5d8,
      0px 30px transparent, -10px 30px transparent, -20px 20px transparent, -30px 10px transparent,
      -30px 0px transparent, -30px -10px transparent, -20px -20px transparent,
      -10px -30px transparent;
  }
  6.25% {
    box-shadow: 0px -30px transparent, 10px -30px #00b5d8, 20px -20px #00b5d8, 30px -10px #00b5d8,
      30px 0px #00b5d8, 30px 10px #00b5d8, 20px 20px #00b5d8, 10px 30px #00b5d8, 0px 30px #00b5d8,
      -10px 30px transparent, -20px 20px transparent, -30px 10px transparent, -30px 0px transparent,
      -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
  }
  12.5% {
    box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px #00b5d8,
      30px -10px #00b5d8, 30px 0px #00b5d8, 30px 10px #00b5d8, 20px 20px #00b5d8, 10px 30px #00b5d8,
      0px 30px #00b5d8, -10px 30px #00b5d8, -20px 20px transparent, -30px 10px transparent,
      -30px 0px transparent, -30px -10px transparent, -20px -20px transparent,
      -10px -30px transparent;
  }
  18.75% {
    box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px transparent,
      30px -10px #00b5d8, 30px 0px #00b5d8, 30px 10px #00b5d8, 20px 20px #00b5d8, 10px 30px #00b5d8,
      0px 30px #00b5d8, -10px 30px #00b5d8, -20px 20px #00b5d8, -30px 10px transparent,
      -30px 0px transparent, -30px -10px transparent, -20px -20px transparent,
      -10px -30px transparent;
  }
  25% {
    box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px transparent,
      30px -10px transparent, 30px 0px #00b5d8, 30px 10px #00b5d8, 20px 20px #00b5d8,
      10px 30px #00b5d8, 0px 30px #00b5d8, -10px 30px #00b5d8, -20px 20px #00b5d8,
      -30px 10px #00b5d8, -30px 0px transparent, -30px -10px transparent, -20px -20px transparent,
      -10px -30px transparent;
  }
  31.25% {
    box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px transparent,
      30px -10px transparent, 30px 0px transparent, 30px 10px #00b5d8, 20px 20px #00b5d8,
      10px 30px #00b5d8, 0px 30px #00b5d8, -10px 30px #00b5d8, -20px 20px #00b5d8,
      -30px 10px #00b5d8, -30px 0px #00b5d8, -30px -10px transparent, -20px -20px transparent,
      -10px -30px transparent;
  }
  37.5% {
    box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px transparent,
      30px -10px transparent, 30px 0px transparent, 30px 10px transparent, 20px 20px #00b5d8,
      10px 30px #00b5d8, 0px 30px #00b5d8, -10px 30px #00b5d8, -20px 20px #00b5d8,
      -30px 10px #00b5d8, -30px 0px #00b5d8, -30px -10px #00b5d8, -20px -20px transparent,
      -10px -30px transparent;
  }
  43.75% {
    box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px transparent,
      30px -10px transparent, 30px 0px transparent, 30px 10px transparent, 20px 20px transparent,
      10px 30px #00b5d8, 0px 30px #00b5d8, -10px 30px #00b5d8, -20px 20px #00b5d8,
      -30px 10px #00b5d8, -30px 0px #00b5d8, -30px -10px #00b5d8, -20px -20px #00b5d8,
      -10px -30px transparent;
  }
  50% {
    box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px transparent,
      30px -10px transparent, 30px 0px transparent, 30px 10px transparent, 20px 20px transparent,
      10px 30px transparent, 0px 30px #00b5d8, -10px 30px #00b5d8, -20px 20px #00b5d8,
      -30px 10px #00b5d8, -30px 0px #00b5d8, -30px -10px #00b5d8, -20px -20px #00b5d8,
      -10px -30px #00b5d8;
  }
  56.25% {
    box-shadow: 0px -30px #00b5d8, 10px -30px transparent, 20px -20px transparent,
      30px -10px transparent, 30px 0px transparent, 30px 10px transparent, 20px 20px transparent,
      10px 30px transparent, 0px 30px transparent, -10px 30px #00b5d8, -20px 20px #00b5d8,
      -30px 10px #00b5d8, -30px 0px #00b5d8, -30px -10px #00b5d8, -20px -20px #00b5d8,
      -10px -30px #00b5d8;
  }
  62.5% {
    box-shadow: 0px -30px #00b5d8, 10px -30px #00b5d8, 20px -20px transparent,
      30px -10px transparent, 30px 0px transparent, 30px 10px transparent, 20px 20px transparent,
      10px 30px transparent, 0px 30px transparent, -10px 30px transparent, -20px 20px #00b5d8,
      -30px 10px #00b5d8, -30px 0px #00b5d8, -30px -10px #00b5d8, -20px -20px #00b5d8,
      -10px -30px #00b5d8;
  }
  68.75% {
    box-shadow: 0px -30px #00b5d8, 10px -30px #00b5d8, 20px -20px #00b5d8, 30px -10px transparent,
      30px 0px transparent, 30px 10px transparent, 20px 20px transparent, 10px 30px transparent,
      0px 30px transparent, -10px 30px transparent, -20px 20px transparent, -30px 10px #00b5d8,
      -30px 0px #00b5d8, -30px -10px #00b5d8, -20px -20px #00b5d8, -10px -30px #00b5d8;
  }
  75% {
    box-shadow: 0px -30px #00b5d8, 10px -30px #00b5d8, 20px -20px #00b5d8, 30px -10px #00b5d8,
      30px 0px transparent, 30px 10px transparent, 20px 20px transparent, 10px 30px transparent,
      0px 30px transparent, -10px 30px transparent, -20px 20px transparent, -30px 10px transparent,
      -30px 0px #00b5d8, -30px -10px #00b5d8, -20px -20px #00b5d8, -10px -30px #00b5d8;
  }
  81.25% {
    box-shadow: 0px -30px #00b5d8, 10px -30px #00b5d8, 20px -20px #00b5d8, 30px -10px #00b5d8,
      30px 0px #00b5d8, 30px 10px transparent, 20px 20px transparent, 10px 30px transparent,
      0px 30px transparent, -10px 30px transparent, -20px 20px transparent, -30px 10px transparent,
      -30px 0px transparent, -30px -10px #00b5d8, -20px -20px #00b5d8, -10px -30px #00b5d8;
  }
  87.5% {
    box-shadow: 0px -30px #00b5d8, 10px -30px #00b5d8, 20px -20px #00b5d8, 30px -10px #00b5d8,
      30px 0px #00b5d8, 30px 10px #00b5d8, 20px 20px transparent, 10px 30px transparent,
      0px 30px transparent, -10px 30px transparent, -20px 20px transparent, -30px 10px transparent,
      -30px 0px transparent, -30px -10px transparent, -20px -20px #00b5d8, -10px -30px #00b5d8;
  }
  93.75% {
    box-shadow: 0px -30px #00b5d8, 10px -30px #00b5d8, 20px -20px #00b5d8, 30px -10px #00b5d8,
      30px 0px #00b5d8, 30px 10px #00b5d8, 20px 20px #00b5d8, 10px 30px transparent,
      0px 30px transparent, -10px 30px transparent, -20px 20px transparent, -30px 10px transparent,
      -30px 0px transparent, -30px -10px transparent, -20px -20px transparent, -10px -30px #00b5d8;
  }
  100% {
    box-shadow: 0px -30px #00b5d8, 10px -30px #00b5d8, 20px -20px #00b5d8, 30px -10px #00b5d8,
      30px 0px #00b5d8, 30px 10px #00b5d8, 20px 20px #00b5d8, 10px 30px #00b5d8,
      0px 30px transparent, -10px 30px transparent, -20px 20px transparent, -30px 10px transparent,
      -30px 0px transparent, -30px -10px transparent, -20px -20px transparent,
      -10px -30px transparent;
  }
}
